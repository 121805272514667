<template>
  <div class="shipping-price-container layout-padding">
    <PageTitle
      class="my-8"
      :title="content.privacyPolicy"
      :childOne="content.privacyPolicy"
      routeOne="/privacypolicy"
      type="withBg"
    />
    <div class="my-16">
      <div class="privacy" v-for="policy in policies" :key="policy.id">
        <div class="title lora-bold">{{ policy.title }}</div>
        <div class="description mt-8">
          {{ policy.content }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["content"],
  mounted() {
    this.$store.dispatch("extra/policies");
  },
  computed: {
    policies() {
      return this.$store.getters["extra/policies"];
    },
  },
};
</script>